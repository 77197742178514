import { useContext, useEffect, useState } from "react";
import { toast } from "../../../components/common/toast";
import {
  getSiteProcesses,
  submitSiteProcesses,
} from "../../../services/Site/Impacts";
import { Roles } from "../../../utils";
import Processes from "../../Process/Processes";
import useSite from "../../../hooks/useSite";
import { Button } from "../../../components/common/button";
import { SaveButtonWrapper } from "../LocationLayout";
import { Processes as ProcessesModel } from "../../../models/Processes";
import SiteElementsRenderer from "../../../components/common/siteElementsRenderer";
import { ModeContext } from "../SiteBCM";
import { SiteModes } from "../../../constants/SiteBCM";
import SiteElementLayout from "../../../components/common/siteElementLayout";
import { StyledTag } from "../../../components/common/common.styles";
import { McNotification } from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";
import { StyledListItem } from "../../../components/common/StyledComponents";
import NotificationWithIcons from "../../../components/common/notificationsWithIcon";
import { siteComponentKeys } from "../../../constants/Site";

export default function SiteProcesses({
  handleStatusChange,
  componentStatus,
  sectionId,
}: Readonly<{
  handleStatusChange: () => void;
  componentStatus: any;
  sectionId: number;
}>) {
  const [processes, setProcesses] = useState<ProcessesModel>();
  const { site, siteId } = useSite();
  const mode = useContext(ModeContext);
  const [infoMessage, setinfoMessage] = useState<any>();

  useEffect(() => {
    if (siteId > 0) {
      getProcessesData();
    }
  }, [siteId]);

  const getProcessesData = () => {
    getSiteProcesses(site.id, site.versionId)
      .then((data) => {
        setProcesses(data);
        handleStatusChange();
        setinfoMessage(getImpactNotificationMessage(data));
      })
      .catch((error) => {
        toast("error", "Error fetching processes", "error");
      });
  };

  const save = () => {
    submitSiteProcesses({
      siteId: site.id,
      versionId: site.versionId,
    })
      .then((res) => {
        toast("success", "Processes saved successfully", "success");
        handleStatusChange();
      })
      .catch((error) => {
        toast("error", error.message, "error");
      });
  };

  const getMasterChangeNotificationMessage = (data: any) => {
    let changesArray = [];
    if (data?.impactConfiguration?.masterChanges?.length > 0) {
      changesArray = data.impactConfiguration.masterChanges
        ? data.impactConfiguration.masterChanges.split(",")
        : [];
    }
    const hasNewProcess = data?.processImpactMappings?.some(
      (process: any) => process.isNew
    );
    if (hasNewProcess) {
      changesArray.push("Processes");
    }
    const changesString = changesArray.join(",");
    if (changesString.length > 0) {
      return (
        <NotificationWithIcons
          message={`Master Configuration has been changed. New ${changesString} are added at archetype. Please configure the impact for the new master configuration.`}
          appearance="warning"
          icon="exclamation-triangle"
        />
      );
    }
    return null;
  };

  const getImpactNotificationMessage = (data: any) => {
    let configuredCriticality =
      data?.impactConfiguration.criticalityMasterValues.find(
        (item: any) => item.id === data?.impactConfiguration.criticalityRTO
      );
    let maxTimeInterval =
      ">" +
      data?.impactConfiguration.timeInterval[
        data?.impactConfiguration.timeInterval.length - 1
      ].name +
      data?.impactConfiguration.timeIntervalUnit;
    let dependencyRto =
      data?.impactConfiguration.depedencyRTO +
      data?.impactConfiguration.timeIntervalUnit;
    return (
      <McNotification appearance="info" fit="small">
        <ul>
          <StyledListItem>
            <p>
              The first time interval that matches the configured criticality{" "}
              {
                <StyledTag bgColor={configuredCriticality.colorCode}>
                  {configuredCriticality.name}
                </StyledTag>
              }{" "}
              or above will be the <b>RTO</b>; else maximum time interval{" "}
              <b>' {maxTimeInterval} '</b> will be the <b>RTO</b>.
            </p>
          </StyledListItem>
          <StyledListItem>
            <p>
              All the Process having RTO lesser than the <b>{dependencyRto} </b>{" "}
              must have dependency mapped in Dependency tab
            </p>
          </StyledListItem>
        </ul>
      </McNotification>
    );
  };

  return (
    <SiteElementLayout
      heading="Impact"
      sectionId={sectionId}
      popoverContent={infoMessage}
    >
      {!componentStatus[siteComponentKeys.impacts].status &&
        getMasterChangeNotificationMessage(processes)}
      <Processes
        key={`${site.id}-${site.versionId}`}
        siteId={site.id}
        processMappings={processes}
        onSave={getProcessesData}
        role={Roles.SITECOORDINATOR}
        archeTypeId={site.archeTypeId}
        shouldShowActionButtons={mode === SiteModes.Write}
      />
      {processes && processes.processImpactMappings.length > 0 && (
        <SiteElementsRenderer mode={mode}>
          <SaveButtonWrapper>
            <Button fit="small" label="Save" click={() => save()} />
          </SaveButtonWrapper>
        </SiteElementsRenderer>
      )}
    </SiteElementLayout>
  );
}
