export const formatTimeInterval = (value: string, unit: string) => {
    // Handle decimal values for hours and minutes
    if (value.includes('.') && unit === 'h') {
      const hours = Math.floor(Number(value));
      const minutes = Math.round((Number(value) - hours) * 60);
      return `${hours}h ${minutes}m`;
    }

    // Handle whole hours
    return `${value}${unit}`;
};
  