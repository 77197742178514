import {
  McInput,
  McOption,
  McSelect,
  McTextAndIcon,
  McTextarea,
} from "@maersk-global/mds-react-wrapper";
import { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import CommonModal from "../../../../components/common/commonModal";
import { toast } from "../../../../components/common/toast";
import { selectionOptions } from "../../../../constants/SelectItems";
import { Customer } from "../../../../models/Customer";
import {
  errorReducer,
  defaultError,
  errorActions,
} from "../../../../reducers/error";
import { SiteCustomer } from "../../../../models/SiteCustomer";
import { ConfigurationMapping } from "../../../../models/AlternativeLocation";
import { customerKeys } from "../SiteCustomers";
import ConfigurableComponentsRenderer from "../../../../components/common/configurableItemsRenderer";
import { saveSiteCustomer } from "../../../../services/Site/Customer";
import useSite from "../../../../hooks/useSite";
import {
  customerActions,
  customerReducer,
  defaultCustomer,
} from "../reducers/SiteCustomerModal";
import { configuration } from "../../../../constants/Site";
import {
  avgWklyinboundoutboundVolumeUnits,
  timeframe,
  typeOfCustomer,
  vertical,
} from "../../../Customers/reducers/customer";
import SiteMandatoryLabel from "../../../../components/common/siteMandatoryLabel";
import Info from "../../../../components/common/info";
import { CustomerInfo } from "../../../../constants/Info";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../../utils/validation-utils";

const expandableSections = [
  {
    label: "CustomerInfo",
    isExpanded: false,
  },
  {
    label: "Requirements",
    isExpanded: false,
  },
  {
    label: "Operation Data",
    isExpanded: false,
  },
  {
    label: "Contact Information",
    isExpanded: false,
  },
];

const regex = /^\d*\.?\d{0,2}$/; // Matches decimal values up to 2 decimal places

export default function AddEditSiteCustomer({
  isOpen,
  editableCustomer,
  configurations,
  onClose,
  onSave,
}: Readonly<{
  isOpen: boolean;
  editableCustomer: SiteCustomer;
  configurations: ConfigurationMapping | undefined;
  onClose: () => void;
  onSave: () => void;
}>): JSX.Element {
  const [customer, dispatch] = useReducer(customerReducer, defaultCustomer);
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);
  const { site } = useSite();
  useEffect(() => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    if (editableCustomer != null) {
      dispatch({
        type: customerActions.SET_CUSTOMER,
        customer: editableCustomer,
      });
      handleSectionExpansion(0);
    } else {
      dispatch({ type: customerActions.RESET_STATE });
      handleSectionExpansion(0);
    }
  }, [isOpen]);

  const [expandableSection, setExpandableSection] =
    useState(expandableSections);

  const handleSectionExpansion = (index: number) => {
    const newExpandableSections = [...expandableSection];

    newExpandableSections.forEach((section) => {
      section.isExpanded = false;
    });

    newExpandableSections[index].isExpanded =
      !newExpandableSections[index].isExpanded;
    setExpandableSection(newExpandableSections);
  };

  const handleInputChange = (e: any) => {
    dispatch({
      type: customerActions.ON_INPUT_CHANGE,
      name: e.target.name,
      value: e.target.value === "" ? null : e.target.value,
    });
  };

  const handleKeyPress = (e: any) => {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 46) {
      let { value } = e.target;
      let updatedValue = `${value}${e.key}`;
      if (
        (regex.test(updatedValue) || value === null || value === "") &&
        parseFloat(updatedValue) <= 100
      ) {
        //Allow to enter
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const onModalClose = () => {
    dispatch({ type: customerActions.RESET_STATE });
    onClose();
  };

  const saveCustomer = () => {
    let updatedCustomer = {
      ...customer,
      siteId: site.id,
      versionId: site.versionId,
      bcpRequirement: customer.bcpRequirement
        ? customer.bcpRequirement === 1
        : null,
      bcpTestRequirement: customer.bcpTestRequirement
        ? customer.bcpTestRequirement === 1
        : null,
      itRecoveryRequirement: customer.itRecoveryRequirement
        ? customer.itRecoveryRequirement === 1
        : null,
      minBusinessContinuityLevel:
        customer.minBusinessContinuityLevel === ""
          ? null
          : parseFloat(customer.minBusinessContinuityLevel),
      avgWklyInboudOutBoundVolume:
        customer.avgWklyInboudOutBoundVolume === ""
          ? null
          : parseFloat(customer.avgWklyInboudOutBoundVolume),
      avgWeeklyVesselCalls:
        customer.avgWeeklyVesselCalls === ""
          ? null
          : parseFloat(customer.avgWeeklyVesselCalls),
      contractualLiabilityToFailure:
        customer.contractualLiabilityToFailure === ""
          ? null
          : parseFloat(customer.contractualLiabilityToFailure),
    };

    if (!validate(updatedCustomer)) {
      return;
    }

    saveSiteCustomer(updatedCustomer)
      .then((res) => {
        toast(
          "Success",
          `Key Customer
        ${customer.id === 0 ? "added" : "updated"} successfully`,
          "success"
        );
        onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const isMandatory = (key: string): boolean => {
    if (!configurations) return false;
    return configurations[key] === configuration.Mandatory;
  };

  const validate = (customer: Customer) => {
    const errorMessages: string[] = [];

    validateCustomerInfo(customer, errorMessages);
    validateRequirements(customer, errorMessages);
    validateOperationData(customer, errorMessages);
    validateContactInformation(customer, errorMessages);

    if (errorMessages.length > 0) {
      dispatchErrors({
        type: errorActions.ADD_ERROR,
        errorMessage: errorMessages,
      });
      return false;
    }
    return true;
  };

  const validateCustomerInfo = (
    customer: Customer,
    errorMessages: string[]
  ) => {
    if (!customer.customerName) {
      errorMessages.push("Customer name is required.");
    }
    if (
      !customer.customerService &&
      isMandatory(customerKeys.customerService)
    ) {
      errorMessages.push("Customer service is required.");
    }
    if (!customer.concernCode && isMandatory(customerKeys.concernCode)) {
      errorMessages.push("Concern code is required.");
    }
    if (!customer.customerCode && isMandatory(customerKeys.customerCode)) {
      errorMessages.push("Customer code is required.");
    }
    if (
      customer.typeOfCustomer === 0 &&
      isMandatory(customerKeys.typeOfCustomer)
    ) {
      errorMessages.push("Type of customer is required.");
    }
    if (customer.vertical === 0 && isMandatory(customerKeys.vertical)) {
      errorMessages.push("Vertical is required.");
    }
  };

  const validateRequirements = (
    customer: Customer,
    errorMessages: string[]
  ) => {
    if (
      customer.bcpRequirement === null &&
      isMandatory(customerKeys.bcpRequirement)
    ) {
      errorMessages.push("BCP Requirement is required.");
    }
    if (
      customer.bcpRequirement &&
      !customer.bcpRequirementComment &&
      isMandatory(customerKeys.bcpRequirementComment)
    ) {
      errorMessages.push(
        "BCP Requirement comment is required when BCP Requirement is Yes."
      );
    }
    if (
      customer.bcpTestRequirement === null &&
      isMandatory(customerKeys.bcpTestRequirement)
    ) {
      errorMessages.push("BCP Test Requirement is required.");
    }
    if (
      customer.bcpTestRequirement &&
      !customer.bcpTestRequirementComment &&
      isMandatory(customerKeys.bcpTestRequirementComment)
    ) {
      errorMessages.push(
        "BCP Test Requirement comment is required when BCP Test Requirement is Yes."
      );
    }
    if (
      customer.itRecoveryRequirement === null &&
      isMandatory(customerKeys.itRecoveryRequirement)
    ) {
      errorMessages.push("IT Recovery Requirement is required.");
    }
    if (
      customer.itRecoveryRequirement &&
      !customer.itRecoveryRequirementComment &&
      isMandatory(customerKeys.itRecoveryRequirementComment)
    ) {
      errorMessages.push(
        "IT Recovery Requirement comment is required when IT Recovery Requirement is Yes."
      );
    }
    if (!customer.bcTolerance && isMandatory(customerKeys.bcTolerence)) {
      errorMessages.push("BC Tolerance is required.");
    }
    if (
      !customer.bcToleranceTimeFrame &&
      isMandatory(customerKeys.bcTolerence)
    ) {
      errorMessages.push("BC Tolerance timeframe is required.");
    }
    if (
      !customer.incidentNotification &&
      isMandatory(customerKeys.incidentNotification)
    ) {
      errorMessages.push("Incident notification is required.");
    }
    if (
      !customer.incidentNotificationTimeframe &&
      isMandatory(customerKeys.incidentNotification)
    ) {
      errorMessages.push("Incident notification timeframe is required.");
    }
  };

  const validateOperationData = (customer: any, errorMessages: string[]) => {
    if (
      !customer.avgWklyInboudOutBoundVolume &&
      isMandatory(customerKeys.avgWeeklyInboundOutboundVolume)
    ) {
      errorMessages.push("Average Weekly Inbound Outbound volume is required.");
    }
    if (
      !customer.avgWklyInboundOutBoundVolumeUnitId &&
      isMandatory(customerKeys.avgWeeklyInboundOutboundVolume)
    ) {
      errorMessages.push("Average Weekly Inbound Outbound volume unit is required.");
    }
    if (
      customer.avgWeeklyVesselCalls === null &&
      isMandatory(customerKeys.avgWeeklyVesselCalls)
    ) {
      errorMessages.push("Average Weekly Vessel Calls is required.");
    }
    if (
      !customer.commitmentService &&
      isMandatory(customerKeys.commitmentServiceOrderFulfillmentLevel)
    ) {
      errorMessages.push(
        "Commitment Service/Order fullfilment level is required."
      );
    }
    if (
      customer.minBusinessContinuityLevel === null &&
      isMandatory(customerKeys.minBusinessContinuityLevel)
    ) {
      errorMessages.push("Min business Continuity Level is required.");
    }
    if (
      customer.contractualLiabilityToFailure === null &&
      isMandatory(customerKeys.contractualLiability)
    ) {
      errorMessages.push(
        "Contractual Liability associated to service failure is required."
      );
    }
  };

  const validateContactInformation = (
    customer: any,
    errorMessages: string[]
  ) => {
    if (
      !customer.contactName &&
      isMandatory(customerKeys.customerContactName)
    ) {
      errorMessages.push("Contact name is required.");
    }
    if (
      isMandatory(customerKeys.customerContactEmail) &&
      !customer.contactEmail
    ) {
      errorMessages.push("Contact email is required.");
    }

    if (
      customer.contactEmail &&
      !validateEmail(customer.contactEmail?.trim())
    ) {
      errorMessages.push("Contact email is invalid");
    }

    if (
      isMandatory(customerKeys.customerContactPhone) &&
      !customer.contactPhone
    ) {
      errorMessages.push("Contact Phone Number is required.");
    }
    if (
      customer.contactPhone &&
      !validatePhoneNumber(customer.contactPhone?.trim())
    ) {
      errorMessages.push("Contact Phone Number is invalid");
    }

    if (
      !customer.contactJobTitle &&
      isMandatory(customerKeys.customerContactJobTitle)
    ) {
      errorMessages.push("Contact job title is required.");
    }
  };

  return (
    <CommonModal
      primaryActionLabel="Confirm"
      primaryAction={() => saveCustomer()}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={() => onModalClose()}
      isOpen={isOpen}
      onModalClose={() => onModalClose()}
      heading={"Add/Edit Key Customer"}
      dimension="medium"
      errors={errors}
      width={674}
    >
      <McTextAndIcon
        label="Customer Info"
        fit="large"
        icon={expandableSection[0].isExpanded ? "chevron-up" : "chevron-down"}
        onClick={() => {
          handleSectionExpansion(0);
        }}
      />
      <TextAndIconBody isExpanded={expandableSection[0].isExpanded}>
        <McInput
          fit="small"
          id="customerName"
          label="Name"
          name="customerName"
          input={(e: any) => handleInputChange(e)}
          value={customer.customerName}
          maxlength={200}
          disabled={customer.isGlobalKeyCustomer}
        >
          <div slot="label" style={{ display: "flex", alignItems: "center" }}>
            <SiteMandatoryLabel label="Name" isMandatory={true} />
            <Info
              popoverContent={CustomerInfo.AddEditCustomer_Name}
              tooltipText=""
            />
          </div>
        </McInput>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.customerService}
        >
          <McInput
            required
            fit="small"
            id="service"
            label=""
            name="customerService"
            input={(e: any) => handleInputChange(e)}
            value={customer.customerService}
            maxlength={200}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Service"
                isMandatory={isMandatory(customerKeys.customerService)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_Service}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>

        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.concernCode}
        >
          <McInput
            fit="small"
            id="concernCode"
            label=""
            name="concernCode"
            input={(e: any) => handleInputChange(e)}
            value={customer.concernCode}
            maxlength={100}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Concern code"
                isMandatory={isMandatory(customerKeys.concernCode)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_ConcerCode}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.customerCode}
        >
          <McInput
            fit="small"
            id="customerCode"
            label=""
            name="customerCode"
            input={(e: any) => handleInputChange(e)}
            value={customer.customerCode}
            maxlength={20}
            disabled={customer.isGlobalKeyCustomer}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Customer code"
              isMandatory={isMandatory(customerKeys.customerCode)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.typeOfCustomer}
        >
          <McSelect
            fit="small"
            id="typeOfCustomer"
            label="Type of customer"
            clearbutton
            name="typeOfCustomer"
            placeholder="Select an option"
            optionselected={(e: any) => handleInputChange(e)}
            value={customer.typeOfCustomer}
            disabled={customer.isGlobalKeyCustomer}
          >
            {typeOfCustomer.map((type) => {
              return (
                <McOption key={type.value} value={type.value}>
                  {type.label}
                </McOption>
              );
            })}
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Type of customer"
                isMandatory={isMandatory(customerKeys.typeOfCustomer)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_TypeOfCustomer}
                tooltipText=""
              />
            </div>
          </McSelect>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.vertical}
        >
          <McSelect
            fit="small"
            id="vertical"
            label=""
            name="vertical"
            clearbutton
            placeholder="Select an option"
            optionselected={(e: any) => handleInputChange(e)}
            value={customer.vertical}
            disabled={customer.isGlobalKeyCustomer}
          >
            {vertical.map((type) => {
              return (
                <McOption key={type.value} value={type.value}>
                  {type.label}
                </McOption>
              );
            })}
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Vertical"
                isMandatory={isMandatory(customerKeys.vertical)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_Vertical}
                tooltipText=""
              />
            </div>
          </McSelect>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.keyClientDirector}
        >
          <McInput
            fit="small"
            id="keyClientDirector"
            label=""
            name="keyClientDirector"
            input={(e: any) => handleInputChange(e)}
            value={customer.keyClientDirector}
            maxlength={100}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Key Client Director"
                isMandatory={isMandatory(customerKeys.keyClientDirector)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_KeyClientDirector}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.cpd}
        >
          <McInput
            fit="small"
            id="cpd"
            label=""
            name="cpd"
            input={(e: any) => handleInputChange(e)}
            value={customer.cpd}
            maxlength={100}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Client Program Director, CPD"
                isMandatory={isMandatory(customerKeys.cpd)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_CPD}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.cpm}
        >
          <McInput
            fit="small"
            id="cpm"
            label=""
            name="cpm"
            input={(e: any) => handleInputChange(e)}
            value={customer.cpm}
            maxlength={100}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Client Program Manager, CPM"
                isMandatory={isMandatory(customerKeys.cpm)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_CPM}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.scdManager}
        >
          <McInput
            fit="small"
            id="scdManager"
            label=""
            name="scdManager"
            input={(e: any) => handleInputChange(e)}
            value={customer.scdManager}
            maxlength={100}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Supply Chain Development, SCD"
                isMandatory={isMandatory(customerKeys.scdManager)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_SCD}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
      </TextAndIconBody>
      <McTextAndIcon
        label="Requirements"
        fit="large"
        icon={expandableSection[1].isExpanded ? "chevron-up" : "chevron-down"}
        onClick={() => {
          handleSectionExpansion(1);
        }}
      />
      <TextAndIconBody isExpanded={expandableSection[1].isExpanded}>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.bcpRequirement}
        >
          <McSelect
            fit="small"
            id="bcpRequirement"
            label=""
            clearbutton
            name="bcpRequirement"
            placeholder="Select an option"
            optionselected={(e: any) => handleInputChange(e)}
            value={customer.bcpRequirement}
            disabled={customer.isGlobalKeyCustomer}
          >
            {selectionOptions.map((option) => {
              return (
                <McOption key={option.label} value={option.value}>
                  {option.label}
                </McOption>
              );
            })}
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="BCP Requirement"
                isMandatory={isMandatory(customerKeys.bcpRequirement)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_BCPRequirement}
                tooltipText=""
              />
            </div>
          </McSelect>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.bcpRequirementComment}
        >
          <McTextarea
            name="bcpRequirementComment"
            rows={5}
            fit={"small"}
            input={(e: any) => handleInputChange(e)}
            value={customer.bcpRequirementComment}
            maxlength={200}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="BCP Requirement Comment"
                isMandatory={isMandatory(customerKeys.bcpRequirementComment)}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_BCPRequirementComment
                }
                tooltipText=""
              />
            </div>
          </McTextarea>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.bcpTestRequirement}
        >
          <McSelect
            fit="small"
            id="bcpTestRequirement"
            label=""
            name="bcpTestRequirement"
            clearbutton
            placeholder="Select an option"
            optionselected={(e: any) => handleInputChange(e)}
            value={customer.bcpTestRequirement}
            disabled={customer.isGlobalKeyCustomer}
          >
            {selectionOptions.map((option) => {
              return (
                <McOption key={option.label} value={option.value}>
                  {option.label}
                </McOption>
              );
            })}
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="BCP Test Requirement"
                isMandatory={isMandatory(customerKeys.bcpTestRequirement)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_BCPTestRequirement}
                tooltipText=""
              />
            </div>
          </McSelect>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.bcpTestRequirementComment}
        >
          <McTextarea
            name="bcpTestRequirementComment"
            label=""
            rows={5}
            fit={"small"}
            input={(e: any) => handleInputChange(e)}
            value={customer.bcpTestRequirementComment}
            maxlength={200}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="BCP Test Requirement Comment"
                isMandatory={isMandatory(
                  customerKeys.bcpTestRequirementComment
                )}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_BCPTestRequirementComment
                }
                tooltipText=""
              />
            </div>
          </McTextarea>
        </ConfigurableComponentsRenderer>

        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.itRecoveryRequirement}
        >
          <McSelect
            fit="small"
            id="itRecoveryRequirement"
            name="itRecoveryRequirement"
            clearbutton
            placeholder="Select an option"
            optionselected={(e: any) => handleInputChange(e)}
            value={customer.itRecoveryRequirement}
            disabled={customer.isGlobalKeyCustomer}
          >
            {selectionOptions.map((option) => {
              return (
                <McOption key={option.label} value={option.value}>
                  {option.label}
                </McOption>
              );
            })}
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="IT Recovery Requirement"
                isMandatory={isMandatory(customerKeys.itRecoveryRequirement)}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_ITRecoveryRequirement
                }
                tooltipText=""
              />
            </div>
          </McSelect>
        </ConfigurableComponentsRenderer>

        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.itRecoveryRequirementComment}
        >
          <McTextarea
            name="itRecoveryRequirementComment"
            label=""
            rows={5}
            fit={"small"}
            input={(e: any) => handleInputChange(e)}
            value={customer.itRecoveryRequirementComment}
            maxlength={200}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="IT Recovery Requirement Comment"
                isMandatory={isMandatory(
                  customerKeys.itRecoveryRequirementComment
                )}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_ITRecoveryRequirementComment
                }
                tooltipText=""
              />
            </div>
          </McTextarea>
        </ConfigurableComponentsRenderer>
        <GridWrapper>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={customerKeys.bcTolerence}
          >
            <McInput
              fit="small"
              id="bcTolerance"
              label=""
              name="bcTolerance"
              input={(e: any) => handleInputChange(e)}
              value={customer.bcTolerance}
              type="number"
              disabled={customer.isGlobalKeyCustomer}
            >
              <div
                slot="label"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SiteMandatoryLabel
                  label="BC Tolerance"
                  isMandatory={isMandatory(customerKeys.bcTolerence)}
                />
                <Info
                  popoverContent={CustomerInfo.AddEditCustomer_BCTolerance}
                  tooltipText=""
                />
              </div>
            </McInput>
            <McSelect
              fit="small"
              id="bcToleranceTimeFrame"
              name="bcToleranceTimeFrame"
              clearbutton
              clearbuttonclick={(e: any) => handleInputChange(e)}
              optionselected={(e: any) => handleInputChange(e)}
              value={customer.bcToleranceTimeFrame}
              disabled={customer.isGlobalKeyCustomer}
            >
              {timeframe.map((option) => {
                return (
                  <McOption key={option.value} value={option.value}>
                    {option.label}
                  </McOption>
                );
              })}
              <div
                slot="label"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SiteMandatoryLabel
                  label="Timeframe"
                  isMandatory={isMandatory(customerKeys.bcTolerence)}
                />
                <Info
                  popoverContent={
                    CustomerInfo.AddEditCustomer_BCToleranceTimeFrame
                  }
                  tooltipText=""
                />
              </div>
            </McSelect>
          </ConfigurableComponentsRenderer>
        </GridWrapper>

        <GridWrapper>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={customerKeys.incidentNotification}
          >
            <McInput
              fit="small"
              id="incidentNotification"
              name="incidentNotification"
              input={(e: any) => handleInputChange(e)}
              value={customer.incidentNotification}
              type="number"
              disabled={customer.isGlobalKeyCustomer}
            >
              <div
                slot="label"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SiteMandatoryLabel
                  label="Incident Notification"
                  isMandatory={isMandatory(customerKeys.incidentNotification)}
                />
                <Info
                  popoverContent={
                    CustomerInfo.AddEditCustomer_IncidentNotification
                  }
                  tooltipText=""
                />
              </div>
            </McInput>
            <McSelect
              fit="small"
              id="incidentNotificationTimeframe"
              clearbutton
              name="incidentNotificationTimeframe"
              optionselected={(e: any) => handleInputChange(e)}
              clearbuttonclick={(e: any) => handleInputChange(e)}
              value={customer.incidentNotificationTimeframe}
              disabled={customer.isGlobalKeyCustomer}
            >
              {timeframe.map((option) => {
                return (
                  <McOption key={option.value} value={option.value}>
                    {option.label}
                  </McOption>
                );
              })}
              <div
                slot="label"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SiteMandatoryLabel
                  label="Timeframe"
                  isMandatory={isMandatory(customerKeys.incidentNotification)}
                />
                <Info
                  popoverContent={
                    CustomerInfo.AddEditCustomer_IncidentNotificationTimeframe
                  }
                  tooltipText=""
                />
              </div>
            </McSelect>
          </ConfigurableComponentsRenderer>
        </GridWrapper>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.incidentNotificationMethod}
        >
          <McTextarea
            name="incidentNotificationMethod"
            label=""
            rows={5}
            fit={"small"}
            input={(e: any) => handleInputChange(e)}
            value={customer.incidentNotificationMethod}
            maxlength={100}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Incident notification method"
                isMandatory={isMandatory(
                  customerKeys.incidentNotificationMethod
                )}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_IncidentNotificationMethod
                }
                tooltipText=""
              />
            </div>
          </McTextarea>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.otherBcRequirements}
        >
          <McTextarea
            label="Other BC requirements"
            rows={5}
            fit={"small"}
            name="otherBCPRequirement"
            input={(e: any) => handleInputChange(e)}
            value={customer.otherBCPRequirement}
            maxlength={200}
            disabled={customer.isGlobalKeyCustomer}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Other BC requirements"
                isMandatory={isMandatory(customerKeys.otherBcRequirements)}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_OtherBCRequirements
                }
                tooltipText=""
              />
            </div>
          </McTextarea>
        </ConfigurableComponentsRenderer>
      </TextAndIconBody>
      <McTextAndIcon
        label="Operation Data"
        fit="large"
        icon={expandableSection[2].isExpanded ? "chevron-up" : "chevron-down"}
        onClick={() => {
          handleSectionExpansion(2);
        }}
      />
      <TextAndIconBody isExpanded={expandableSection[2].isExpanded}>
        <GridWrapper>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={customerKeys.avgWeeklyInboundOutboundVolume}
          >
            <McInput
              fit="small"
              id="avgWklyInboudOutBoundVolume"
              label=""
              name="avgWklyInboudOutBoundVolume"
              input={(e: any) => handleInputChange(e)}
              value={customer.avgWklyInboudOutBoundVolume}
              type="number"
            >
              <div
                slot="label"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SiteMandatoryLabel
                  label="Average Weekly Inbound/Outbound Volume"
                  isMandatory={isMandatory(
                    customerKeys.avgWeeklyInboundOutboundVolume
                  )}
                />
                <Info
                  popoverContent={
                    CustomerInfo.AddEditCustomer_AvgWeeklyInboundOutboundVolume
                  }
                  tooltipText=""
                />
              </div>
            </McInput>
          </ConfigurableComponentsRenderer>

          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={customerKeys.avgWeeklyInboundOutboundVolume}
          >
            <McSelect
              fit="small"
              id="avgWklyInboundOutBoundVolumeUnitId"
              label=""
              name="avgWklyInboundOutBoundVolumeUnitId"
              optionselected={(e: any) => handleInputChange(e)}
              value={customer.avgWklyInboundOutBoundVolumeUnitId}
            >
              {avgWklyinboundoutboundVolumeUnits.map((type) => {
                return (
                  <McOption key={type.value} value={type.value}>
                    {type.label}
                  </McOption>
                );
              })}
              <div
                slot="label"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SiteMandatoryLabel
                  label="Unit"
                  isMandatory={isMandatory(
                    customerKeys.avgWeeklyInboundOutboundVolume
                  )}
                />
                <Info
                  popoverContent={
                    CustomerInfo.AddEditCustomer_AvgWeeklyInboundOutboundVolumeUnits
                  }
                  tooltipText=""
                />
              </div>
            </McSelect>
          </ConfigurableComponentsRenderer>
        </GridWrapper>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.avgWeeklyVesselCalls}
        >
          <McInput
            fit="small"
            id="avgWeeklyVesselCalls"
            label=""
            name="avgWeeklyVesselCalls"
            input={(e: any) => handleInputChange(e)}
            value={customer.avgWeeklyVesselCalls}
            type="number"
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Average Weekly Vessel Calls"
                isMandatory={isMandatory(customerKeys.avgWeeklyVesselCalls)}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_AvgWeeklyVesselCalls
                }
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.commitmentServiceOrderFulfillmentLevel}
        >
          <McInput
            fit="small"
            id="commitmentService"
            label=""
            name="commitmentService"
            input={(e: any) => handleInputChange(e)}
            value={customer.commitmentService}
            maxlength={100}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Commitment Service/Order fullfilment level"
                isMandatory={isMandatory(
                  customerKeys.commitmentServiceOrderFulfillmentLevel
                )}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_CommitmentService}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.minBusinessContinuityLevel}
        >
          <McInput
            fit="small"
            id="minBusinessContinuityLevel"
            label=""
            name="minBusinessContinuityLevel"
            keypress={(e: any) => handleKeyPress(e)}
            input={(e: any) => handleInputChange(e)}
            value={customer.minBusinessContinuityLevel}
            suffix={"%"}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Min business Continuity Level"
                isMandatory={isMandatory(
                  customerKeys.minBusinessContinuityLevel
                )}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_MinBusinessContinuityLevel
                }
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.contractualLiability}
        >
          <McInput
            fit="small"
            id="contractualLiabilityToFailure"
            label=""
            name="contractualLiabilityToFailure"
            input={(e: any) => handleInputChange(e)}
            value={customer.contractualLiabilityToFailure}
            mask="000,000,000,000,000,000,000,000"
            suffix={"$"}
            maxlength={19}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Contractual Liability associated to service failure"
                isMandatory={isMandatory(customerKeys.contractualLiability)}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_ContractualLiabilityFailure
                }
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
      </TextAndIconBody>
      <McTextAndIcon
        label="Contact Information"
        fit="large"
        icon={expandableSection[3].isExpanded ? "chevron-up" : "chevron-down"}
        onClick={() => {
          handleSectionExpansion(3);
        }}
      />
      <TextAndIconBody isExpanded={expandableSection[3].isExpanded}>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.customerContactName}
        >
          <McInput
            fit="small"
            id="contactName"
            label=""
            name="contactName"
            input={(e: any) => handleInputChange(e)}
            value={customer.contactName}
            maxlength={100}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Contact Person(Name)"
                isMandatory={isMandatory(customerKeys.customerContactName)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_ContactPersonName}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.customerContactEmail}
        >
          <McInput
            fit="small"
            id="contactEmail"
            label=""
            name="contactEmail"
            input={(e: any) => handleInputChange(e)}
            value={customer.contactEmail}
            maxlength={100}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Email"
                isMandatory={isMandatory(customerKeys.customerContactEmail)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_ContactPersonEmail}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.customerContactPhone}
        >
          <McInput
            fit="small"
            id="contactPhone"
            label=""
            name="contactPhone"
            input={(e: any) => handleInputChange(e)}
            value={customer.contactPhone}
            maxlength={100}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Phone Number"
                isMandatory={isMandatory(customerKeys.customerContactPhone)}
              />
              <Info
                popoverContent={CustomerInfo.AddEditCustomer_ContactPersonPhone}
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={customerKeys.customerContactJobTitle}
        >
          <McInput
            fit="small"
            id="contactJobTitle"
            label=""
            name="contactJobTitle"
            input={(e: any) => handleInputChange(e)}
            value={customer.contactJobTitle}
            maxlength={100}
          >
            <div slot="label" style={{ display: "flex", alignItems: "center" }}>
              <SiteMandatoryLabel
                label="Job Title"
                isMandatory={isMandatory(customerKeys.customerContactJobTitle)}
              />
              <Info
                popoverContent={
                  CustomerInfo.AddEditCustomer_ContactPersonJobTitle
                }
                tooltipText=""
              />
            </div>
          </McInput>
        </ConfigurableComponentsRenderer>
      </TextAndIconBody>
    </CommonModal>
  );
}

export const TextAndIconBody = styled.div<{ isExpanded?: boolean }>`
  padding: 5px 10px 10px 10px;
  display: ${({ isExpanded }) => (isExpanded ? "" : "none")};
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-content: center;
`;
